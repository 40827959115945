
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,600);
//@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,700');

@charset 'utf-8';
	
// Settings
@import "settings";		

// Foundation
@import "node_modules/foundation-sites/scss/foundation";

// Include Everything (True) for the Flex Grid :);
@include foundation-everything(true);

